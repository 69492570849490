import { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "./../Componets/Banner.css";

export const imageData = [
  {
    label: "Image 1",
    alt: "image1",
    url: "https://playsongshome.playsongs.ai/charlie/home/banner-2403/pc/banner1.jpg",
  },

  {
    label: "Image 2",
    alt: "image2",
    url: "https://playsongshome.playsongs.ai/charlie/home/banner-2403/pc/banner2.jpg",
  },

  {
    label: "Image 3",
    alt: "image3",
    url: "https://playsongshome.playsongs.ai/charlie/home/banner-2403/pc/banner3.jpg",
  },

  {
    label: "Image 4",
    alt: "image4",
    url: "https://playsongshome.playsongs.ai/charlie/home/banner-2403/pc/banner4.jpg",
  },
  {
    label: "Image 5",
    alt: "image5",
    url: "https://playsongshome.playsongs.ai/charlie/home/banner-2403/pc/banner5.jpg",
  },
  {
    label: "Image 6",
    alt: "image6",
    url: "https://playsongshome.playsongs.ai/charlie/home/banner-2403/pc/banner6.jpg",
  },
];

export const imageDataTablet = [
  {
    label: "Image 1",
    alt: "image1",
    url: "https://playsongshome.playsongs.ai/charlie/home/banner-2403/tablet/main_ta_1.jpg",
  },

  {
    label: "Image 2",
    alt: "image2",
    url: "https://playsongshome.playsongs.ai/charlie/home/banner-2403/tablet/main_ta_2.jpg",
  },

  {
    label: "Image 3",
    alt: "image3",
    url: "https://playsongshome.playsongs.ai/charlie/home/banner-2403/tablet/main_ta_3.jpg",
  },

  {
    label: "Image 4",
    alt: "image4",
    url: "https://playsongshome.playsongs.ai/charlie/home/banner-2403/tablet/main_ta_4.jpg",
  },
  {
    label: "Image 5",
    alt: "image5",
    url: "https://playsongshome.playsongs.ai/charlie/home/banner-2403/tablet/main_ta_5.jpg",
  },
  {
    label: "Image 6",
    alt: "image6",
    url: "https://playsongshome.playsongs.ai/charlie/home/banner-2403/tablet/main_ta_6.jpg",
  },
];

export const imageDataMobile = [
  {
    label: "Image 1",
    alt: "image1",
    url: "https://playsongshome.playsongs.ai/charlie/home/banner-2403/mobile/mo_430-388-1.jpg",
  },

  {
    label: "Image 2",
    alt: "image2",
    url: "https://playsongshome.playsongs.ai/charlie/home/banner-2403/mobile/mo_430-388-2.jpg",
  },

  {
    label: "Image 3",
    alt: "image3",
    url: "https://playsongshome.playsongs.ai/charlie/home/banner-2403/mobile/mo_430-388-3.jpg",
  },

  {
    label: "Image 4",
    alt: "image4",
    url: "https://playsongshome.playsongs.ai/charlie/home/banner-2403/mobile/mo_430-388-4.jpg",
  },
  {
    label: "Image 5",
    alt: "image5",
    url: "https://playsongshome.playsongs.ai/charlie/home/banner-2403/mobile/mo_430-388-5.jpg",
  },
  {
    label: "Image 6",
    alt: "image6",
    url: "https://playsongshome.playsongs.ai/charlie/home/banner-2403/mobile/mo_430-388-6.jpg",
  },
];
const renderSlides = imageData.map((image) => (
  <div key={image.alt}>
    <img src={image.url} alt={image.alt} />
  </div>
));

const renderSlidesTablet = imageDataTablet.map((image) => (
  <div key={image.alt}>
    <img src={image.url} alt={image.alt} />
  </div>
));

const renderSlidesMobile = imageDataMobile.map((image) => (
  <div key={image.alt}>
    <img src={image.url} alt={image.alt} />
  </div>
));

function Banner() {
  const [currentIndex, setCurrentIndex] = useState();
  function handleChange(index) {
    setCurrentIndex(index);
  }

  return (
    <div className="flex justify-center items-center py-5 px-3">
      <Carousel
        showStatus={false}
        showArrows={true}
        autoPlay={true}
        infiniteLoop={true}
        showThumbs={false}
        selectedItem={imageData[currentIndex]}
        onChange={handleChange}
        className="w-[400px] lg:hidden"
      >
        {renderSlides}
      </Carousel>
    </div>
  );
}

export function BannerTablet() {
  const [currentIndex, setCurrentIndex] = useState();
  function handleChange(index) {
    setCurrentIndex(index);
  }

  return (
    <Carousel
      showStatus={false}
      showArrows={true}
      autoPlay={true}
      infiniteLoop={true}
      showThumbs={false}
      selectedItem={imageData[currentIndex]}
      onChange={handleChange}
    >
      {renderSlidesTablet}
    </Carousel>
  );
}

export function BannerMobile() {
  const [currentIndex, setCurrentIndex] = useState();
  function handleChange(index) {
    setCurrentIndex(index);
  }

  return (
    <Carousel
      showStatus={false}
      showArrows={true}
      autoPlay={true}
      infiniteLoop={true}
      showThumbs={false}
      selectedItem={imageData[currentIndex]}
      onChange={handleChange}
    >
      {renderSlidesMobile}
    </Carousel>
  );
}
/*const Banner = ({ children }) => {
  const [currentIndex, setCurrentIndex] = useState();
  function handleChange(index) {
    setCurrentIndex(index);
  }

  return (
    <div className="flex justify-center items-center py-5 px-3">
      <Carousel
        showStatus={false}
        showArrows={true}
        autoPlay={true}
        infiniteLoop={true}
        showThumbs={false}
        selectedItem={imageData[currentIndex]}
        onChange={handleChange}
        className="w-[400px] lg:hidden"
      >
        {renderSlides}
      </Carousel>
    </div>
  );
};*/

export default Banner;
