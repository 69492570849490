import {
  BodyView,
  HomeTitleView,
  HomeDescriptionView,
  HomeImageSection,
  HomeLeftImageView,
  HomeRightImageView,
  HomeHiestoryView,
  HomeHistroyDescriptionView,
  HomeHistroyTextView,
  HomeAdmissionInformationView,
  HomeAdmissionInformationTextView,
  HomeAdmissionInformationDescriptionView,
  HomeAdmissionInformationButtonView,
  HomeAdmissionInfomationImageView,
  HomeFamilyInformationView,
  HomeFamilyInfoVeriants,
  HomeFamilyInfoVeriants2,
  HomeFamilyInfoImageView,
  HomeFamilyInfoImageView2,
  HomeFamilyInfoTextView,
  handleOpenNewTab,
  handleLink,
  PathStringView,
  HomeImageZone,
  HomeFamilyInfoTextView2,
} from "./common";

import Banner, { BannerMobile, BannerTablet } from "../Componets/Banner";
import { Default, Desktop, Mobile, Tablet } from "../Device";
import { useEffect, useState } from "react";
import Popup from "../Componets/Popup";
import { useCookies } from "react-cookie";
import useAxios from "../useAxios";

interface PopupData {
  id: string;
  URL: string;
  link: string;
  startday: string;
  endday: string;
}

function Home() {
  // const [loading, setLoading] = useState(true);
  const [popupData, setPopupData] = useState<PopupData | null>(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [hasCookie, setHasCookie] = useState(true);
  const [appCookies, setAppCookies] = useCookies();
  /*
  const { loading, data, error } = useAxios({
    url: "/popup1.json",
  });


  const getDate = (days: string) => {
    const date = new Date(days);
    return date;
  };

  const getPopupDay = () => {
    if (!popupData) return false;
    const today = new Date();
    today.setDate(today.getDate());

    const startday = new Date(popupData?.startday);
    const endday = new Date(popupData?.endday);

    if (startday <= today) {
      if (today <= endday) return true;
    }

    return false;
  };

  useEffect(() => {
    if (!data) return;
    var jsondata = JSON.stringify(data);

    let josnparse: PopupData = JSON.parse(jsondata);
    //    josnparse.startday = "2023-10-24 11:00:00";
    //  josnparse.endday = "2023-10-25 15:00:00";
    setPopupData(josnparse);
  }, [loading]);

  useEffect(() => {
    if (!getPopupDay()) return;

    if (appCookies["POPUP_EXPIRES"]) return;
    console.log(appCookies["POPUP_EXPIRES"]);
    setHasCookie(false);
    setOpenPopup(true);
  }, [popupData != null]);
*/

  const getExpiredDate = (days: number) => {
    const date = new Date();
    date.setDate(date.getDate() + days);
    return date;
  };

  const closePopupUntilExpires = () => {
    if (!appCookies) return;

    const expires = getExpiredDate(1);
    setAppCookies("POPUP_EXPIRES", true, { path: "/", expires });

    setOpenPopup(false);
  };

  return (
    <div>
      <>
        {openPopup && (
          <Popup
            closePopup={() => setOpenPopup(false)}
            clicklink={""}
            imglink={
              "https://playsongshome.playsongs.ai/popup/%E1%84%87%E1%85%A1%E1%84%8B%E1%85%B5%E1%84%89%E1%85%B5%E1%84%8F%E1%85%B3%E1%86%AF+17%E1%84%8C%E1%85%AE%E1%84%82%E1%85%A7%E1%86%AB+%E1%84%80%E1%85%B5%E1%84%82%E1%85%A7%E1%86%B7_%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%89%E1%85%B3%E1%84%90%E1%85%A1+%E1%84%86%E1%85%B5%E1%86%BE+%E1%84%8C%E1%85%A1%E1%84%89%E1%85%A1%E1%84%86%E1%85%A9%E1%86%AF+%E1%84%91%E1%85%A1%E1%86%B8%E1%84%8B%E1%85%A5%E1%86%B8.jpg"
            }
          />
        )}
      </>

      <HomeView />
    </div>
  );
}

function HomeView() {
  return (
    <>
      <Mobile>
        {" "}
        <MobileHomeView />{" "}
      </Mobile>
      <Tablet>
        {" "}
        <TabletHomeView />{" "}
      </Tablet>
      <Desktop>
        <DesktopHome />{" "}
      </Desktop>
    </>
  );
}
function MobileHomeView() {
  return (
    <>
      <BannerMobile />
      <BodyView>
        <HomeTitleView className="text_wrap">
          WELCOME to{"\n"}CHARLIE’S BIG RED HOUSE!
        </HomeTitleView>
        <DescriptionView />
        <ImageSectionView />
        <AdmissionView />
        <TabletMobileStoreBanner />
      </BodyView>
    </>
  );
}
function TabletHomeView() {
  return (
    <>
      <BodyView>
        <BannerTablet />
        <HomeTitleView className="text_wrap">
          WELCOME to CHARLIE’S BIG RED HOUSE!
        </HomeTitleView>
        <DescriptionView />
        <ImageSectionView />
        <AdmissionView />
        <TabletMobileStoreBanner />
      </BodyView>
    </>
  );
}

function DesktopHome() {
  return (
    <BodyView className="text_wrap">
      <PathStringView className="path" />
      <Banner />
      <HomeTitleView className="text_wrap">
        WELCOME to CHARLIE’S BIG RED HOUSE!
      </HomeTitleView>
      <DescriptionView />
      <ImageSectionView />
      <AdmissionView />
      <StoreBanner />
    </BodyView>
  );
}

function TabletMobileStoreBanner() {
  return (
    <>
      <HomeFamilyInfoVeriants
        onClick={() => handleOpenNewTab("http://www.playsongs.co.kr")}
      >
        <HomeFamilyInfoImageView>
          <img
            src="https://playsongshome.playsongs.ai/charlie/home/tablet/intro_playsongs.png"
            alt="character"
            width={"100%"}
          />
        </HomeFamilyInfoImageView>
        <HomeFamilyInfoTextView className="text_wrap">
          <Tablet>찰리와 함께하면{"\n"}더 좋은 플레이송스를 만나보세요</Tablet>
          <Mobile>
            찰리와 함께하면{"\n"}더 좋은 플레이송스를{"\n"}만나보세요
          </Mobile>
        </HomeFamilyInfoTextView>
      </HomeFamilyInfoVeriants>

      <HomeFamilyInfoVeriants2
        onClick={() => handleOpenNewTab("https://smartstore.naver.com/bicycle")}
      >
        <HomeFamilyInfoImageView2>
          <img
            src={
              "https://playsongshome.playsongs.ai/charlie/home/tablet/intro_store.png"
            }
            alt="character"
            width={"100%"}
          />
        </HomeFamilyInfoImageView2>
        <HomeFamilyInfoTextView2 className="text_wrap">
          찰리 친구들이라면{"\n"}
          플송 스토어
        </HomeFamilyInfoTextView2>
      </HomeFamilyInfoVeriants2>
    </>
  );
}

function StoreBanner() {
  return (
    <HomeFamilyInformationView>
      <HomeFamilyInfoVeriants
        onClick={() => handleOpenNewTab("http://www.playsongs.co.kr")}
      >
        <HomeFamilyInfoImageView>
          <img
            src="https://playsongshome.playsongs.ai/charlie/home/tablet/intro_playsongs.png"
            alt="character"
            width={"100%"}
          />
        </HomeFamilyInfoImageView>
        <HomeFamilyInfoTextView className="text_wrap">
          <Desktop>
            찰리와 함께하면{"\n"}더 좋은 플레이송스를{"\n"}만나보세요.
          </Desktop>
          <Tablet>찰리와 함께하면{"\n"}더 좋은 플레이송스를 만나보세요</Tablet>
          <Mobile>찰리와 함께하면{"\n"}더 좋은 플레이송스를 만나보세요</Mobile>
        </HomeFamilyInfoTextView>
      </HomeFamilyInfoVeriants>
      <HomeFamilyInfoVeriants
        onClick={() => handleOpenNewTab("https://smartstore.naver.com/bicycle")}
      >
        <HomeFamilyInfoImageView2>
          <img
            src={
              "https://playsongshome.playsongs.ai/charlie/home/intro_store.png"
            }
            alt="character"
            width={"100%"}
          />
        </HomeFamilyInfoImageView2>
        <HomeFamilyInfoTextView
          className="text_wrap"
          style={{ right: "3.5131%" }}
        >
          찰리 친구들이라면{"\n"}
          플송 스토어
        </HomeFamilyInfoTextView>
      </HomeFamilyInfoVeriants>
    </HomeFamilyInformationView>
  );
}
function AdmissionView() {
  return (
    <HomeAdmissionInformationView onClick={() => handleLink("/Admission")}>
      <HomeAdmissionInformationTextView>
        <HomeAdmissionInformationDescriptionView>
          If you have any questions about getting into the Charlie's Big Red
          House?
        </HomeAdmissionInformationDescriptionView>
        <HomeAdmissionInformationButtonView className="text_wrap">
          입학 상담안내{"  "} {"＞"}
        </HomeAdmissionInformationButtonView>
      </HomeAdmissionInformationTextView>
      <HomeAdmissionInfomationImageView>
        <img
          src="https://playsongshome.playsongs.ai/charlie/home/main_charlie.png"
          alt="character"
          width={"100%"}
        />
      </HomeAdmissionInfomationImageView>
    </HomeAdmissionInformationView>
  );
}

function DescriptionView() {
  return (
    <>
      <HomeDescriptionView className="text_wrap">
        We focus on inquiry and bilingualism empowers young minds to embrace
        complexity, think critically from multiple perspectives.{"\n"}
        We provide a joyful, warm, and stimulating first school experience for
        our students to enjoy learning and to become a confident and caring
        individual.
      </HomeDescriptionView>
    </>
  );
}

function ImageSectionView() {
  return (
    <>
      <HomeImageZone>
        <Mobile>
          <HomeImageSection>
            <img
              src="https://playsongshome.playsongs.ai/charlie/home/mobile/20210903-n-152%403x.png"
              alt="left"
              width={"100%"}
            />
          </HomeImageSection>
        </Mobile>
        <Default>
          <HomeImageSection>
            <HomeLeftImageView>
              <Tablet>
                <img
                  src="https://playsongshome.playsongs.ai/charlie/home/tablet/20210903-n-200%403x-1.png"
                  alt="left"
                  width={"100%"}
                />
              </Tablet>
              <Desktop>
                <img
                  src="https://playsongshome.playsongs.ai/charlie/home/20210903-n-200%403x.png"
                  alt="left"
                  width={"100%"}
                />
              </Desktop>
            </HomeLeftImageView>
            <HomeRightImageView>
              <Tablet>
                <img
                  src="https://playsongshome.playsongs.ai/charlie/home/tablet/20210903-n-152%403x-1.png"
                  alt="left"
                  width={"100%"}
                />
              </Tablet>
              <Desktop>
                <img
                  src="https://playsongshome.playsongs.ai/charlie/home/20210903-n-152%403x.png"
                  alt="left"
                  width={"100%"}
                />
              </Desktop>
            </HomeRightImageView>
          </HomeImageSection>
        </Default>

        <HomeHiestoryView>
          <HomeHistroyTextView>
            Our History and Proven Curriculum
          </HomeHistroyTextView>
          <HomeHistroyDescriptionView>
            Since 2007, our “activity, inquiry based learning” approach has been
            favored by many parents for their child’s learning. Our graduate
            students have progressed to top Korean and International schools
            successfully.
          </HomeHistroyDescriptionView>
        </HomeHiestoryView>
      </HomeImageZone>
    </>
  );
}

export default Home;
function fetchData(arg0: {
  url: string;
  method: string;
  headers: { "Content-Type": string };
  data: PopupData | null;
}) {
  throw new Error("Function not implemented.");
}
