import { Desktop, Mobile, Tablet } from "../../Device";
import {
  BodyView,
  PathStringView,
  AUMVTitleView,
  AUMVTitle2View,
  AUMVDescriptionView0,
  AUMVDescriptionView,
  AUMVDescriptionView2,
  AUMVDescriptionView3,
  AUMVImgView,
} from "../common";

function MissionandValue() {
  return (
    <div>
      <BodyView>
        <Desktop>
          <PathStringView className="path">
            About Us {">"} Mission and Value
          </PathStringView>
          <div className="separatorline" />
        </Desktop>
        <AUMVTitleView>CHARLIE’S BIG RED HOUSE</AUMVTitleView>
        <AUMVDescriptionView0 className="text_wrap">
          <Desktop>
            찰리스빅레드하우스는 아동 발달을 바탕으로 적기에 맞는 신체,
            사회정서, 인지발달 커리큘럼을 이중언어(한국어/영어)로{"\n"}교육하는
            이중언어 교육기관입니다.
          </Desktop>
          <Tablet>
            찰리스빅레드하우스는 아동 발달을 바탕으로 적기에 맞는 신체,
            사회정서, 인지발달{"\n"}커리큘럼을 이중언어(한국어/영어)로 교육하는
            이중언어 교육기관입니다.
          </Tablet>
          <Mobile>
            찰리스빅레드하우스는 아동 발달을 바탕으로 적기에 맞는{"\n"}신체,
            사회정서, 인지발달 커리큘럼을 이중언어{"\n"}(한국어/영어)로 교육하는
            이중언어 교육기관입니다.
          </Mobile>
        </AUMVDescriptionView0>
        <AUMVTitle2View>Mission</AUMVTitle2View>
        <AUMVDescriptionView>
          Encourage all children to achieve above and beyond their potential.
        </AUMVDescriptionView>
        <AUMVDescriptionView2>
          학생들이 본인의 잠재력 이상의 것을 성취하도록 격려합니다.
        </AUMVDescriptionView2>
        <AUMVImgView>
          <img
            src="https://playsongshome.playsongs.ai/charlie/aboutus/misson-1%403x.png"
            alt="img"
            width={"100%"}
          />
        </AUMVImgView>
        <AUMVDescriptionView3>
          상호작용의 원리/놀이 중심의 원리/생활 중심의 원리
        </AUMVDescriptionView3>
      </BodyView>
    </div>
  );
}

export default MissionandValue;

//
