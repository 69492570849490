import { styled } from "styled-components";
import { Default, Desktop, Mobile, Tablet } from "../Device";
import {
  AdmissionPageTitleView,
  AdmissionSectionView,
  AdmissionButtonView,
  AdmissionCampusSectionView,
  BannerView,
  BodyView,
  PathStringView,
  AdmissionCampusLinkView,
  LearningImageView,
  handleOpenNewTab,
} from "./common";

function Admission() {
  return (
    <>
      <Default>
        <DesktopAdmission />
      </Default>
      <Mobile>
        <MobileAdmission />
      </Mobile>
    </>
  );
}
function MobileAdmission() {
  return (
    <>
      <LearningImageView style={{ marginTop: "0" }}>
        <img
          src="https://playsongshome.playsongs.ai/charlie/admission/mobile/banner.png"
          alt="banner admission"
          width={"100%"}
        />
      </LearningImageView>
      <BodyView>
        <AdmissionPageTitleView
          className="text_wrap"
          style={{ marginTop: "2.6042vw" }}
        >
          HanNam Program{"\n"}(2~6 age)
        </AdmissionPageTitleView>

        <AdmissionButtonView
          onClick={() =>
            handleOpenNewTab("https://forms.gle/LZ74fua6xHLisY2FA")
          }
        >
          <span>Request Information</span>
        </AdmissionButtonView>

        <AdmissionPageTitleView className="text_wrap">
          gwanggyo Pre-K and Kinder Program {"\n"}(4~6 age)
        </AdmissionPageTitleView>
        <AdmissionButtonView
          onClick={() =>
            handleOpenNewTab(
              "https://docs.google.com/forms/d/1L-ghFFkiyTEu-Kkp0HI-aofdtNXR7E_wi0mgdGX1KMQ/viewform?edit_requested=true"
            )
          }
        >
          <span>Request Information</span>
        </AdmissionButtonView>
        <AdmissionButtonView
          style={{ marginTop: "5.8140vw" }}
          onClick={() =>
            handleOpenNewTab("https://forms.gle/Di2PPFNx9j4xNy4YA")
          }
        >
          <span>Visit Us</span>
        </AdmissionButtonView>

        <AdmissionPageTitleView className="text_wrap">
          Gwanggyo Elementary Program {"\n"}(7~9 age)
        </AdmissionPageTitleView>
        <AdmissionButtonView
          onClick={() =>
            handleOpenNewTab(
              "https://docs.google.com/forms/d/1c-yoy6nduAyF-g2-kWjuR8NDgjfRguxMc2UHiFSApt0/viewform?edit_requested=true"
            )
          }
        >
          <span>Request Information</span>
        </AdmissionButtonView>
        <AdmissionButtonView
          style={{ marginTop: "5.8140vw" }}
          onClick={() =>
            handleOpenNewTab("https://forms.gle/8uPcG5J1WFsFEiDF6")
          }
        >
          <span>Visit Us</span>
        </AdmissionButtonView>

        <AdmissionCampusSectionView className="text_wrap">
          <AdmissionCampusLinkView
            onClick={() =>
              handleOpenNewTab(
                "https://playsongshome.playsongs.ai/charlie/academyfee/%E1%84%80%E1%85%AD%E1%84%89%E1%85%B3%E1%86%B8%E1%84%87%E1%85%B5-%E1%84%8E%E1%85%A1%E1%86%AF%E1%84%85%E1%85%B5%E1%84%89%E1%85%B3%E1%84%87%E1%85%B5%E1%86%A8%E1%84%85%E1%85%A6%E1%84%83%E1%85%B3%E1%84%92%E1%85%A1%E1%84%8B%E1%85%AE%E1%84%89%E1%85%B3(%E1%84%92%E1%85%A1%E1%86%B8%E1%84%87%E1%85%A9%E1%86%AB).jpg"
              )
            }
          >
            Campus Hannam1{"\n"}
            <span style={{ fontWeight: "normal", fontFamily: "Pretendard" }}>
              찰리스빅레드하우스학원 제2893호{"\n"}찰리스어학원 제2993호{"\n"}
              {"  "}
            </span>
          </AdmissionCampusLinkView>
          <AdmissionCampusLinkView
            onClick={() =>
              handleOpenNewTab(
                "https://playsongshome.playsongs.ai/charlie/academyfee/%E1%84%8E%E1%85%A1%E1%86%AF%E1%84%85%E1%85%B5%E1%84%89%E1%85%B32%E1%84%80%E1%85%AA%E1%86%AB%E1%84%8B%E1%85%A5%E1%84%92%E1%85%A1%E1%86%A8%E1%84%8B%E1%85%AF%E1%86%AB+%E1%84%80%E1%85%AD%E1%84%89%E1%85%B3%E1%86%B8%E1%84%87%E1%85%B5.jpg"
              )
            }
          >
            Campus Hannam2{"\n"}
            <span style={{ fontWeight: "normal", fontFamily: "Pretendard" }}>
              찰리스2관어학원 제3198호{"\n"}
              {"  "}
            </span>
          </AdmissionCampusLinkView>
          <AdmissionCampusLinkView
            onClick={() =>
              handleOpenNewTab(
                "https://playsongshome.playsongs.ai/charlie/academyfee/%E1%84%80%E1%85%AA%E1%86%BC%E1%84%80%E1%85%AD_%E1%84%80%E1%85%AD%E1%84%89%E1%85%B3%E1%86%B8%E1%84%87%E1%85%B5.jpg"
              )
            }
          >
            Campus Gwanggyo{"\n"}
            <span style={{ fontWeight: "normal", fontFamily: "Pretendard" }}>
              찰리스빅레드하우스광교어학원 제7119호{"\n"}
              {"  "}
            </span>
          </AdmissionCampusLinkView>
        </AdmissionCampusSectionView>
      </BodyView>
    </>
  );
}

function DesktopAdmission() {
  return (
    <BodyView>
      <Desktop>
        <PathStringView className="path">Admission</PathStringView>
        <div className="separatorline" />

        <BannerView>
          <img
            src="https://playsongshome.playsongs.ai/charlie/admission/banner.png"
            alt="banner admission"
            width={"100%"}
          />
        </BannerView>
      </Desktop>
      <Tablet>
        <LearningImageView style={{ marginTop: "0" }}>
          <img
            src="https://playsongshome.playsongs.ai/charlie/admission/tablet/banner.png"
            alt="banner admission"
            width={"100%"}
          />
        </LearningImageView>
        <AdmissionPageTitleView style={{ marginTop: "2.6042vw" }}>
          HanNam Program (2~6 age)
        </AdmissionPageTitleView>
      </Tablet>
      <Desktop>
        <AdmissionPageTitleView>
          HanNam Program (2~6 age)
        </AdmissionPageTitleView>
      </Desktop>
      <AdmissionSectionView>
        <AdmissionButtonView
          onClick={() =>
            handleOpenNewTab("https://forms.gle/LZ74fua6xHLisY2FA")
          }
        >
          <span>Request Information</span>
        </AdmissionButtonView>
      </AdmissionSectionView>
      <Tablet>
        <AdmissionPageTitleView className="text_wrap">
          gwanggyo Pre-K and Kinder Program {"\n"}(4~6 age)
        </AdmissionPageTitleView>
      </Tablet>
      <Desktop>
        <AdmissionPageTitleView>
          gwanggyo Pre-K and Kinder Program (4~6 age)
        </AdmissionPageTitleView>
      </Desktop>
      <AdmissionSectionView>
        <AdmissionButtonView
          onClick={() =>
            handleOpenNewTab(
              "https://docs.google.com/forms/d/1L-ghFFkiyTEu-Kkp0HI-aofdtNXR7E_wi0mgdGX1KMQ/viewform?edit_requested=true"
            )
          }
        >
          <span>Request Information</span>
        </AdmissionButtonView>
        <AdmissionButtonView
          onClick={() =>
            handleOpenNewTab("https://forms.gle/Di2PPFNx9j4xNy4YA")
          }
        >
          <span>Visit Us</span>
        </AdmissionButtonView>
      </AdmissionSectionView>
      <Tablet>
        <AdmissionPageTitleView className="text_wrap">
          Gwanggyo Elementary Program {"\n"}(7~9 age)
        </AdmissionPageTitleView>
      </Tablet>
      <Desktop>
        <AdmissionPageTitleView className="text_wrap">
          Gwanggyo Elementary Program (7~9 age)
        </AdmissionPageTitleView>
      </Desktop>
      <AdmissionSectionView>
        <AdmissionButtonView
          onClick={() =>
            handleOpenNewTab(
              "https://docs.google.com/forms/d/1c-yoy6nduAyF-g2-kWjuR8NDgjfRguxMc2UHiFSApt0/viewform?edit_requested=true"
            )
          }
        >
          <span>Request Information</span>
        </AdmissionButtonView>
        <AdmissionButtonView
          onClick={() =>
            handleOpenNewTab("https://forms.gle/8uPcG5J1WFsFEiDF6")
          }
        >
          <span>Visit Us</span>
        </AdmissionButtonView>
      </AdmissionSectionView>
      <AdmissionCampusSectionView className="text_wrap">
        <AdmissionCampusLinkView
          onClick={() =>
            handleOpenNewTab(
              "https://playsongshome.playsongs.ai/charlie/academyfee/%E1%84%80%E1%85%AD%E1%84%89%E1%85%B3%E1%86%B8%E1%84%87%E1%85%B5-%E1%84%8E%E1%85%A1%E1%86%AF%E1%84%85%E1%85%B5%E1%84%89%E1%85%B3%E1%84%87%E1%85%B5%E1%86%A8%E1%84%85%E1%85%A6%E1%84%83%E1%85%B3%E1%84%92%E1%85%A1%E1%84%8B%E1%85%AE%E1%84%89%E1%85%B3(%E1%84%92%E1%85%A1%E1%86%B8%E1%84%87%E1%85%A9%E1%86%AB).jpg"
            )
          }
        >
          Campus Hannam1{"\n"}
          <span style={{ fontWeight: "normal", fontFamily: "Pretendard" }}>
            찰리스빅레드하우스학원 제2893호{"\n"}찰리스어학원 제2993호{"\n"}
            {"  "}
          </span>
        </AdmissionCampusLinkView>
        <AdmissionCampusLinkView
          onClick={() =>
            handleOpenNewTab(
              "https://playsongshome.playsongs.ai/charlie/academyfee/%E1%84%8E%E1%85%A1%E1%86%AF%E1%84%85%E1%85%B5%E1%84%89%E1%85%B32%E1%84%80%E1%85%AA%E1%86%AB%E1%84%8B%E1%85%A5%E1%84%92%E1%85%A1%E1%86%A8%E1%84%8B%E1%85%AF%E1%86%AB+%E1%84%80%E1%85%AD%E1%84%89%E1%85%B3%E1%86%B8%E1%84%87%E1%85%B5.jpg"
            )
          }
        >
          Campus Hannam2{"\n"}
          <span style={{ fontWeight: "normal", fontFamily: "Pretendard" }}>
            찰리스2관어학원 제3198호{"\n"}
            {"  "}
          </span>
        </AdmissionCampusLinkView>
        <AdmissionCampusLinkView
          onClick={() =>
            handleOpenNewTab(
              "https://playsongshome.playsongs.ai/charlie/academyfee/%E1%84%80%E1%85%AA%E1%86%BC%E1%84%80%E1%85%AD_%E1%84%80%E1%85%AD%E1%84%89%E1%85%B3%E1%86%B8%E1%84%87%E1%85%B5.jpg"
            )
          }
        >
          Campus Gwanggyo{"\n"}
          <span style={{ fontWeight: "normal", fontFamily: "Pretendard" }}>
            찰리스빅레드하우스광교어학원 제7119호{"\n"}
            {"  "}
          </span>
        </AdmissionCampusLinkView>
      </AdmissionCampusSectionView>
    </BodyView>
  );
}

export default Admission;
