import { Desktop, Mobile, Tablet } from "../../Device";
import {
  LearningPageInfoImgView,
  LearningPageInfoText2View,
  LearningPageInfoView,
  LearningPageInfoTextView,
  LearningPageInfoDescriptionView,
  LearningPageInfoLinkView,
  LearningPageInfoLinkTextView,
  LearningPageInfoLinkSectionView,
  LearningPageInfoLinkText2View,
  BodyView,
  PathStringView,
  LearningImageView,
  BannerView,
  LearningPageTitleView,
  handleOpenNewTab,
} from "../common";

function PreSchool() {
  return (
    <>
      <Desktop>
        <DesktopPreSchool />
      </Desktop>
      <Tablet>
        <TabletPreSchool />
      </Tablet>
      <Mobile>
        <MobilePreSchool />
      </Mobile>
    </>
  );
}
function MobilePreSchool() {
  return (
    <>
      <LearningImageView style={{ marginTop: "0" }}>
        <img
          src="https://playsongshome.playsongs.ai/charlie/learning/mobile/mobile-banner-elemantary-01%403x.png"
          alt="banner-pre"
          width={"100%"}
        />
      </LearningImageView>
      <BodyView>
        <LearningPageTitleView>
          HanNam Toddler Program (2~3 age)
        </LearningPageTitleView>
        <LearningPageInfoTextView className="text_wrap">
          At CHARLIE’S BIG RED HOUSE, our goal is to create a positive
          first-learning experience. When children and families arrive each day,
          they are warmly welcomed. Each class follows a daily schedule that
          includes a circle time, a read-aloud, free play, outdoor play, and a
          special class such as Library, Music, or Storytelling. The schedule
          helps build a routine while providing a structure for learning and a
          feeling of comfort in their classroom environment.
        </LearningPageInfoTextView>
        <LearningPageInfoImgView>
          <img
            src="https://playsongshome.playsongs.ai/charlie/learning/tablet/hannam-1-beans-01%403x.png"
            alt="banner-elemantary"
            width={"100%"}
          />
        </LearningPageInfoImgView>
        <LearningPageInfoText2View className="text_wrap">
          찰리스빅레드하우스에서는 학생들에게 긍정적인 첫 배움 경험을 만드는
          것입니다. 아이들과 가족들이 매일 도착하면, 따뜻하게 환영을 받으며
          하루가 시작합니다. 각 수업은 서클 타임, 독서, 탐구 놀이, 야외 활동,
          체육, 음악, 그리고 스토리텔링과 같은 특별한 수업을 포함하는 매일의
          일정을 따릅니다. 이와 같은 일과는 교실에서의 편안함을 제공하면서
          교육환경에 적응력을 자연스럽게 구성하는 데 도움을 줍니다.
        </LearningPageInfoText2View>

        <LearningPageInfoImgView>
          <img
            src="https://playsongshome.playsongs.ai/charlie/learning/tablet/hannam-1-beans-02%403x.png"
            alt="banner-elemantary"
            width={"100%"}
          />
        </LearningPageInfoImgView>
        <LearningPageInfoLinkTextView
          onClick={() =>
            handleOpenNewTab("https://forms.gle/LZ74fua6xHLisY2FA")
          }
        >
          → Request information
        </LearningPageInfoLinkTextView>
        <LearningPageInfoLinkTextView
          onClick={() =>
            handleOpenNewTab("https://forms.gle/sdWYFkXZ79Awb5FY6")
          }
        >
          → Visit Us
        </LearningPageInfoLinkTextView>
      </BodyView>
    </>
  );
}

function TabletPreSchool() {
  return (
    <>
      <BodyView>
        <LearningPageInfoImgView style={{ marginTop: "0" }}>
          <img
            src="https://playsongshome.playsongs.ai/charlie/learning/tablet/banner-pre-hannam1-01%403x.png"
            alt="banner-pre"
            width={"100%"}
          />
        </LearningPageInfoImgView>

        <LearningPageTitleView>
          HanNam Toddler Program (2~3 age)
        </LearningPageTitleView>
        <LearningPageInfoTextView className="text_wrap">
          At CHARLIE’S BIG RED HOUSE, our goal is to create a positive
          first-learning experience. When children and families arrive each day,
          they are warmly welcomed. Each class follows a daily schedule that
          includes a circle time, a read-aloud, free play, outdoor play, and a
          special class such as Library, Music, or Storytelling. The schedule
          helps build a routine while providing a structure for learning and a
          feeling of comfort in their classroom environment.
        </LearningPageInfoTextView>
        <LearningPageInfoImgView>
          <img
            src="https://playsongshome.playsongs.ai/charlie/learning/tablet/hannam-1-beans-01%403x.png"
            alt="banner-elemantary"
            width={"100%"}
          />
        </LearningPageInfoImgView>
        <LearningPageInfoText2View className="text_wrap">
          찰리스빅레드하우스에서는 학생들에게 긍정적인 첫 배움 경험을 만드는
          것입니다. 아이들과 가족들이 매일 도착하면, 따뜻하게 환영을 받으며
          하루가 시작합니다. 각 수업은 서클 타임, 독서, 탐구 놀이, 야외 활동,
          체육, 음악, 그리고 스토리텔링과 같은 특별한 수업을 포함하는 매일의
          일정을 따릅니다. 이와 같은 일과는 교실에서의 편안함을 제공하면서
          교육환경에 적응력을 자연스럽게 구성하는 데 도움을 줍니다.
        </LearningPageInfoText2View>

        <LearningPageInfoImgView>
          <img
            src="https://playsongshome.playsongs.ai/charlie/learning/tablet/hannam-1-beans-02%403x.png"
            alt="banner-elemantary"
            width={"100%"}
          />
        </LearningPageInfoImgView>
        <LearningPageInfoLinkTextView
          onClick={() =>
            handleOpenNewTab("https://forms.gle/LZ74fua6xHLisY2FA")
          }
        >
          {" "}
          → Request information
        </LearningPageInfoLinkTextView>
        <LearningPageInfoLinkTextView
          onClick={() =>
            handleOpenNewTab("https://forms.gle/sdWYFkXZ79Awb5FY6")
          }
        >
          {" "}
          → Visit Us
        </LearningPageInfoLinkTextView>
      </BodyView>
    </>
  );
}

function DesktopPreSchool() {
  return (
    <BodyView>
      <PathStringView className="path">
        Learning {">"} HanNam Toddler Program (2~3 age)
      </PathStringView>
      <div className="separatorline" />
      <BannerView>
        <img
          src="https://playsongshome.playsongs.ai/charlie/learning/pc/0321/banner-pre-hannam1.png"
          alt="banner-pre"
          width={"100%"}
        />
      </BannerView>

      <LearningPageTitleView>
        HanNam Toddler Program (2~3 age)
      </LearningPageTitleView>

      <LearningPageInfoView>
        <LearningPageInfoDescriptionView>
          <LearningPageInfoTextView className="text_wrap">
            At CHARLIE’S BIG RED HOUSE, our goal is to create a positive{"\n"}
            first-learning experience. When children and families arrive each{" "}
            {"\n"}day, they are warmly welcomed. Each class follows a daily
            schedule {"\n"}that includes a circle time, a read-aloud, free play,
            outdoor play, {"\n"}and a special class such as Library, Music, or
            Storytelling. The {"\n"}schedule helps build a routine while
            providing a structure for {"\n"}learning and a feeling of comfort in
            their classroom environment.
          </LearningPageInfoTextView>
          <LearningPageInfoImgView>
            <img
              src="https://playsongshome.playsongs.ai/charlie/learning/hannam-1-beans-01%403x.png"
              alt="img02"
              width={"100%"}
            />
          </LearningPageInfoImgView>

          <LearningPageInfoText2View className="text_wrap">
            찰리스빅레드하우스에서는 학생들에게 긍정적인 첫 배움 경험을 만드는
            것입니다. {"\n"}아이들과 가족들이 매일 도착하면, 따뜻하게 환영을
            받으며 하루가 시작합니다. {"\n"}각 수업은 서클 타임, 독서, 탐구
            놀이, 야외 활동, 체육, 음악, 그리고 스토리텔링과 {"\n"}같은 특별한
            수업을 포함하는 매일의 일정을 따릅니다. 이와 같은 일과는 교실에서의{" "}
            {"\n"}편안함을 제공하면서 교육 환경에 적응력을 자연스럽게 구성하는
            데 도움을 줍니다.
          </LearningPageInfoText2View>

          <LearningPageInfoImgView>
            <img
              src="https://playsongshome.playsongs.ai/charlie/learning/hannam-1-beans-02%403x.png"
              alt="img02"
              width={"100%"}
            />
          </LearningPageInfoImgView>
        </LearningPageInfoDescriptionView>
        <LearningPageInfoLinkSectionView>
          <LearningPageInfoLinkView>
            <LearningPageInfoLinkTextView
              onClick={() =>
                handleOpenNewTab("https://forms.gle/LZ74fua6xHLisY2FA")
              }
            >
              {" "}
              → Request information
            </LearningPageInfoLinkTextView>
          </LearningPageInfoLinkView>
        </LearningPageInfoLinkSectionView>
      </LearningPageInfoView>
    </BodyView>
  );
}

export default PreSchool;
