import * as RiIcons from "react-icons/ri";

export const SidebarData = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "About Us",
    path: "/AboutUs",
    iconClosed: <RiIcons.RiArrowDownSLine />,
    iconOpened: <RiIcons.RiArrowUpSLine />,

    subNav: [
      {
        title: "Mission and Value",
        path: "/AboutUs/MissionandValue",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "Learning",
    path: "/Learning",
    iconClosed: <RiIcons.RiArrowDownSLine />,
    iconOpened: <RiIcons.RiArrowUpSLine />,

    subNav: [
      {
        title: "HanNam Toddler Program (2~3 age)",
        path: "/Learning/PreSchool",
        cName: "sub-nav",
      },
      {
        title: "HanNam Pre-K and Kinder Program (4~6 age)",
        path: "/Learning/PrimarySchoolHannam2",
        cName: "sub-nav",
      },

      {
        title: "Gwanggyo Pre-K and Kinder Program (4~6 age)",
        path: "/Learning/PrimarySchoolGwanggyo",
        cName: "sub-nav",
      },

      {
        title: "Gwanggyo Elementary Program (7~9 age)",
        path: "/Learning/Elemantary",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "Admission",
    path: "/Admission",
  },
  {
    title: "Contact Us",
    path: "/ContactUs",
  },
  {
    title: "Work With Us",
    path: "/WorkWithUs",
  },
  {
    title: "Products",
    path: "",
    iconClosed: <RiIcons.RiArrowDownSLine />,
    iconOpened: <RiIcons.RiArrowUpSLine />,

    subNav: [
      {
        title: "PlaysongsHome",
        path: "https://www.playsongshome.com/",
      },
      {
        title: "Store",
        path: "https://smartstore.naver.com/bicycle",
      },
    ],
  },
  {
    title: "Our Service",
    path: "/FamilySite",
  },
];
