import { Desktop, Mobile, Tablet } from "../../Device";
import {
  BodyView,
  PathStringView,
  AUSectionView,
  AUImageView,
  AUTextView,
  AUTitleView,
  AUDescriptionView,
} from "../common";

function AboutUs() {
  return (
    <>
      <Mobile>
        <MobileAboutUs />
      </Mobile>
      <Tablet>
        <TabletAboutUs />
      </Tablet>
      <Desktop>
        <DesktopAboutUs />
      </Desktop>
    </>
  );
}

function MobileAboutUs() {
  return (
    <>
      <AUImageView>
        <img
          src="https://playsongshome.playsongs.ai/charlie/aboutus/mobile/banner.png"
          alt="aboutus_img01"
          width={"100%"}
        />
      </AUImageView>
      <BodyView>
        <AUTitleView className="text_wrap">
          Welcome to CHARLIE’S{"\n"}BIG RED HOUSE What is{"\n"}our Education
          Focus?
        </AUTitleView>
        <AUDescriptionView className="text_wrap">
          We focus on inquiry and bilingualism empowers young minds to embrace
          complexity, think critically from multiple perspectives.{"\n"}
          We provide a joyful, warm, and stimulating first learning
          experience for our students to enjoy learning and to become a
          confident and caring individual.
        </AUDescriptionView>
      </BodyView>
    </>
  );
}

function TabletAboutUs() {
  return (
    <BodyView>
      <AUImageView>
        <img
          src="https://playsongshome.playsongs.ai/charlie/aboutus/tablet/banner.png"
          alt="aboutus_img01"
          width={"100%"}
        />
      </AUImageView>
      <AUTitleView className="text_wrap">
        Welcome to CHARLIE’S BIG RED HOUSE{"\n"}What is our Education Focus?
      </AUTitleView>
      <AUDescriptionView className="text_wrap">
        We focus on inquiry and bilingualism empowers young minds to embrace
        complexity, think critically from multiple perspectives.{"\n"}
        We provide a joyful, warm, and stimulating first learning experience for
        our students to enjoy learning and to become a confident and caring
        individual.
      </AUDescriptionView>
    </BodyView>
  );
}

function DesktopAboutUs() {
  return (
    <BodyView>
      <PathStringView className="path">About Us</PathStringView>
      <div className="separatorline" />
      <AUSectionView>
        <AUImageView>
          <img
            src="https://playsongshome.playsongs.ai/charlie/aboutus/banner.png"
            alt="aboutus_img01"
            width={"100%"}
          />
        </AUImageView>
        <AUTextView>
          <AUTitleView className="text_wrap">
            Welcome to{"\n"}
            CHARLIE’S BIG RED HOUSE{"\n"}
            What is our Education Focus?{"\n"}
          </AUTitleView>
          <AUDescriptionView className="text_wrap">
            We focus on inquiry and bilingualism empowers young minds to embrace
            complexity, think critically from multiple perspectives.{"\n"}
            We provide a joyful, warm, and stimulating first learning
            experience for our students to enjoy learning and to become a
            confident and caring individual.
          </AUDescriptionView>
        </AUTextView>
      </AUSectionView>
    </BodyView>
  );
}

export default AboutUs;

//
