interface IBasicInfo {
  pc: string;
  tablet: string;
  mobile: string;
}

interface IFooterString {
  title: string;
  description: IBasicInfo;
  copy: string;
}

interface IAppDecriptionInfo {
  title: string;
  desc: string;
}
interface IAppService {
  title: string;
  homeDecription: IAppDecriptionInfo;
  partyDecription: IAppDecriptionInfo;
  download: string;
}
export class StringManager {
  static footerString: IFooterString = {
    title: "Bicycle Co., Ltd\n",
    description: {
      pc: "CEO: Lee Do-hee | Business Number: 114-86-61839 (Republic of Korea) | Tel. 1668-0822\nAddress: 98, Dokseodang-ro, Yongsan-gu, Seoul | Chief privacy officer: Young-Ju An Info @playsongs.co.kr",
      tablet:
        "CEO: Lee Do-hee\nBusiness Number: 114-86-61839 (Republic of Korea)\nTel. 1668-0822 Address: 98, Dokseodang-ro, Yongsan-gu, Seoul\nChief privacy Officer: Young-Ju An Info @playsongs.co.kr",
      mobile:
        "CEO: Lee Do-hee | Business Number: 114-86-61839\n(Republic of Korea) | Tel. 1668-0822\nAddress: 98, Dokseodang-ro, Yongsan-gu, Seoul\nChief privacy Officer: Young-Ju An Info @playsongs.co.kr",
    },
    copy: "© BICYCLE Co.,Ltd. ALL RIGHTS RESERVED.",
  };

  static appserviceString: IAppService = {
    title: "APP SERVICE",
    download: "앱 다운받으러 가기",
    homeDecription: {
      title: "Playsongshome",
      desc: "6만 명 이상 고객이 선택한 프로그램 플레이송스를 집에서!\n음악치료사들이 기획한! 유일한 음악중심 통합발달 프로그램!\n미국 엄마들에게도 인정받은 '맘스초이스 어워드' 골드 메달!\n영상과 교구재 멀티링크로 더욱 효과적인 프로그램.\n국어로 한번 영어로 한번 자연스러운 첫 영어 만남.\n월령별 발달에 맞는 체계적인 놀이법과 기획된 음악을 통해 아이들은 \n신나게 놀면서도 밸런스 있게 발달하고 인지능력을 키웁니다.\n2007년부터 현장 경험과 데이터 기반으로 만들어진 프로그램 입니다.",
    },
    partyDecription: {
      title: "Playsong's Party",
      desc: "플레이송스 사운드북을 통해 음악으로 배우고 학습하여\n아이의 사고력과 창의성을 증진시키기 위한 놀이학습 프로그램",
    },
  };
}
