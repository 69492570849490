import { Desktop, Mobile, Tablet } from "../../Device";
import {
  BodyView,
  PathStringView,
  LearningDescriptionView,
  LearningSectionView,
  LearningImageView,
  LearningTextView,
  LearningTitleView,
} from "../common";

function Learning() {
  return (
    <>
      <Desktop>
        <DesktopLearning />
      </Desktop>
      <Tablet>
        <TabletLearning />
      </Tablet>
      <Mobile>
        <MobileLearning />
      </Mobile>
    </>
  );
}

function MobileLearning() {
  return (
    <>
      <LearningImageView style={{ marginTop: "0" }}>
        <img
          src="https://playsongshome.playsongs.ai/charlie/learning/tablet/learning1.png"
          alt="preschool_img01.jpg"
          width={"100%"}
        />
      </LearningImageView>
      <BodyView>
        <LearningTitleView>Language Arts/Literacy</LearningTitleView>
        <LearningDescriptionView>
          Children who challenge bilingualism learn the joy of learning a new
          language and grow into global citizens with confidence. Natural
          language development occurs due to bilingual exposure that focuses on
          reading, writing, and speaking.
        </LearningDescriptionView>
      </BodyView>

      <LearningImageView>
        <img
          src="https://playsongshome.playsongs.ai/charlie/learning/tablet/learning2.png"
          alt="preschool_img02.jpg"
          width={"100%"}
        />
      </LearningImageView>

      <BodyView>
        <LearningTitleView>Numbers and Sensory</LearningTitleView>
        <LearningDescriptionView>
          Using storytelling and tools to learn the concepts of numbers and
          computations, shapes, measurements, regularity, probability, and
          statistics enhancing to build problem solving skills as well.
        </LearningDescriptionView>
      </BodyView>
      <LearningImageView>
        <img
          src="https://playsongshome.playsongs.ai/charlie/learning/tablet/learning3.png"
          alt="preschool_img03.jpg"
          width={"100%"}
        />
      </LearningImageView>
      <BodyView>
        <LearningTitleView>Arts and Music</LearningTitleView>
        <LearningDescriptionView>
          Art classes and cultural experiences add to children's imagination and
          creativity. It also helps them to have broader understandings of
          what’s around them.
        </LearningDescriptionView>
      </BodyView>

      <LearningImageView>
        <img
          src="https://playsongshome.playsongs.ai/charlie/learning/tablet/learning4.png"
          alt="preschool_img04.jpg"
          width={"100%"}
        />
      </LearningImageView>

      <BodyView>
        <LearningTitleView>After Class Program</LearningTitleView>
        <LearningDescriptionView>
          Through dynamic programs like physical education, English, spatial
          perception, and art, language, confidence, creativity, and sociality
          and expressiveness are developed.
        </LearningDescriptionView>
      </BodyView>
    </>
  );
}

function TabletLearning() {
  return (
    <BodyView>
      <LearningImageView style={{ marginTop: "0" }}>
        <img
          src="https://playsongshome.playsongs.ai/charlie/learning/tablet/learning1.png"
          alt="preschool_img01.jpg"
          width={"100%"}
        />
      </LearningImageView>
      <LearningTextView>
        <LearningTitleView>Language Arts/Literacy</LearningTitleView>
        <LearningDescriptionView>
          Children who challenge bilingualism learn the joy of learning a new
          language and grow into global citizens with confidence. Natural
          language development occurs due to bilingual exposure that focuses on
          reading, writing, and speaking.
        </LearningDescriptionView>
      </LearningTextView>

      <LearningImageView>
        <img
          src="https://playsongshome.playsongs.ai/charlie/learning/tablet/learning2.png"
          alt="preschool_img02.jpg"
          width={"100%"}
        />
      </LearningImageView>
      <LearningTextView>
        <LearningTitleView>Numbers and Sensory</LearningTitleView>
        <LearningDescriptionView>
          Using storytelling and tools to learn the concepts of numbers and
          computations, shapes, measurements, regularity, probability, and
          statistics enhancing to build problem solving skills as well.
        </LearningDescriptionView>
      </LearningTextView>
      <LearningImageView>
        <img
          src="https://playsongshome.playsongs.ai/charlie/learning/tablet/learning3.png"
          alt="preschool_img03.jpg"
          width={"100%"}
        />
      </LearningImageView>
      <LearningTextView>
        <LearningTitleView>Arts and Music</LearningTitleView>
        <LearningDescriptionView>
          Art classes and cultural experiences add to children's imagination and
          creativity. It also helps them to have broader understandings of
          what’s around them.
        </LearningDescriptionView>
      </LearningTextView>
      <LearningImageView>
        <img
          src="https://playsongshome.playsongs.ai/charlie/learning/tablet/learning4.png"
          alt="preschool_img04.jpg"
          width={"100%"}
        />
      </LearningImageView>
      <LearningTextView>
        <LearningTitleView>After Class Program</LearningTitleView>
        <LearningDescriptionView>
          Through dynamic programs like physical education, English, spatial
          perception, and art, language, confidence, creativity, and sociality
          and expressiveness are developed.
        </LearningDescriptionView>
      </LearningTextView>
    </BodyView>
  );
}

function DesktopLearning() {
  return (
    <BodyView>
      <PathStringView className="path">Learning</PathStringView>
      <div className="separatorline" />

      <LearningSectionView>
        <LearningImageView>
          <img
            src="https://playsongshome.playsongs.ai/charlie/learning/learning1.png"
            alt="preschool_img01.jpg"
            width={"100%"}
          />
        </LearningImageView>
        <LearningTextView style={{ marginLeft: "1.0938vw" }}>
          <LearningTitleView>Language Arts/Literacy</LearningTitleView>
          <LearningDescriptionView>
            Children who challenge bilingualism learn the joy of learning a new
            language and grow into global citizens with confidence. Natural
            language development occurs due to bilingual exposure that focuses
            on reading, writing, and speaking.
          </LearningDescriptionView>
        </LearningTextView>
      </LearningSectionView>
      <LearningSectionView>
        <LearningTextView>
          <LearningTitleView>Numbers and Sensory</LearningTitleView>
          <LearningDescriptionView>
            Using storytelling and tools to learn the concepts of numbers and
            computations, shapes, measurements, regularity, probability, and
            statistics enhancing to build problem solving skills as well.
          </LearningDescriptionView>
        </LearningTextView>
        <LearningImageView style={{ marginLeft: "1.0938vw" }}>
          <img
            src="https://playsongshome.playsongs.ai/charlie/learning/pc/learning2.png"
            alt="preschool_img02.jpg"
            width={"100%"}
          />
        </LearningImageView>
      </LearningSectionView>
      <LearningSectionView>
        <LearningImageView>
          <img
            src="https://playsongshome.playsongs.ai/charlie/learning/learning3.png"
            alt="preschool_img03.jpg"
            width={"100%"}
          />
        </LearningImageView>
        <LearningTextView style={{ marginLeft: "1.0938vw" }}>
          <LearningTitleView>Arts and Music</LearningTitleView>
          <LearningDescriptionView>
            Art classes and cultural experiences add to children's imagination
            and creativity. It also helps them to have broader understandings of
            what’s around them.
          </LearningDescriptionView>
        </LearningTextView>
      </LearningSectionView>
      <LearningSectionView>
        <LearningTextView>
          <LearningTitleView>After Class Program</LearningTitleView>
          <LearningDescriptionView>
            Through dynamic programs like physical education, English, spatial
            perception, and art, language, confidence, creativity, and sociality
            and expressiveness are developed.
          </LearningDescriptionView>
        </LearningTextView>
        <LearningImageView style={{ marginLeft: "1.0938vw" }}>
          <img
            src="https://playsongshome.playsongs.ai/charlie/learning/learning4.png"
            alt="preschool_img04.jpg"
            width={"100%"}
          />
        </LearningImageView>
      </LearningSectionView>
    </BodyView>
  );
}

export default Learning;
