import { Desktop, Mobile, Tablet } from "../../Device";
import {
  LearningPageInfoImgView,
  LearningPageInfoTextView2,
  LearningPageInfoTextView,
  LearningPageInfoText2View,
  LearningPageInfoView,
  LearningPageInfoDescriptionView,
  LearningPageInfoLinkView,
  LearningPageInfoLinkTextView,
  LearningPageInfoLinkSectionView,
  LearningPageInfoLinkText2View,
  BannerView,
  BodyView,
  PathStringView,
  LearningPageTitleView,
  handleOpenNewTab,
} from "../common";

function PrimarySchoolGwanggyo() {
  return (
    <>
      <Desktop>
        <DesktopPrimarySchoolGwanggyo />
      </Desktop>
      <Tablet>
        <TabletPrimarySchoolGwanggyo />
      </Tablet>
      <Mobile>
        <MobilePrimarySchoolGwanggyo />
      </Mobile>
    </>
  );
}

function MobilePrimarySchoolGwanggyo() {
  return (
    <>
      <LearningPageInfoImgView style={{ marginTop: "0" }}>
        <img
          src="https://playsongshome.playsongs.ai/charlie/learning/mobile/banner-gwang-01.png"
          alt="banner-elemantary"
          width={"100%"}
        />
      </LearningPageInfoImgView>
      <BodyView className="text_wrap">
        <TabletMobilePrimarySchoolGwanggyo />
      </BodyView>
    </>
  );
}

function TabletPrimarySchoolGwanggyo() {
  return (
    <BodyView className="text_wrap">
      <LearningPageInfoImgView style={{ marginTop: "0" }}>
        <img
          src="https://playsongshome.playsongs.ai/charlie/learning/banner-gwang-01%403x.png"
          alt="banner-elemantary"
          width={"100%"}
        />
      </LearningPageInfoImgView>
      <TabletMobilePrimarySchoolGwanggyo />
    </BodyView>
  );
}

function TabletMobilePrimarySchoolGwanggyo() {
  return (
    <>
      <LearningPageTitleView>
        gwanggyo Pre-K and Kinder Program (4~6 age)
      </LearningPageTitleView>

      <LearningPageInfoTextView className="text_wrap">
        CHARLIE’S BIG RED HOUSE Gwanggyo campus features an integrated learning
        environment specially designed to stimulate the learning and development
        of children from primary Kinder Program, age 4 through to 6 and
        elementary, age 7 to 9(grade 1,2,3), based on international curricular
        standards.
      </LearningPageInfoTextView>
      <LearningPageInfoText2View className="text_wrap">
        찰리스빅레드하우스 광교 캠퍼스는 국제적인 교과 과정 기준 (International
        Curriculum Standard) 에 따라 5세부터 7세까지의 유치부 프로그램으로
        구성되어 있습니다. 아이들의 학습과 발달을 촉진하기 위해 특별히 설계된
        통합 학습 환경을 갖추고 있습니다.
      </LearningPageInfoText2View>

      <LearningPageInfoImgView>
        <img
          src="https://playsongshome.playsongs.ai/charlie/learning/tablet/ta-banner-elemantary-01%403x.png"
          alt="img03"
          width={"100%"}
        />
      </LearningPageInfoImgView>

      <LearningPageInfoTextView2 className="text_wrap">
        Through an english immersion program where children’s interests and
        ideas inform the design of curricular experiences, using multiple ways
        of learning, incorporating social and emotional development, language,
        physical development, reading, writing, maths, social studies, musical,
        stem, science, and debate students develop to become a confident
        learner.
      </LearningPageInfoTextView2>
      <LearningPageInfoImgView>
        <img
          src="https://playsongshome.playsongs.ai/charlie/learning/gwang-pri-img02%403x.png"
          alt="img04"
          width={"100%"}
        />
      </LearningPageInfoImgView>
      <LearningPageInfoText2View className="text_wrap">
        아이들의 관심사와 생각을 중점으로 한 영어 몰입교육 프로그램으로 사회 및
        정서 발달, 언어, 신체 발달, 읽기, 쓰기, 수학, 사회, 음악, STEM, 과학 및
        토론을 통합하는 다양한 학습 방법을 통해 새로운 환경을 탐색하며 배움과
        학습에 자신감이 있는 학생으로 성장합니다.
      </LearningPageInfoText2View>

      <LearningPageInfoLinkTextView
        onClick={() =>
          handleOpenNewTab(
            "https://docs.google.com/forms/d/1L-ghFFkiyTEu-Kkp0HI-aofdtNXR7E_wi0mgdGX1KMQ/viewform?edit_requested=true"
          )
        }
      >
        {" "}
        → Request information
      </LearningPageInfoLinkTextView>
      <LearningPageInfoLinkTextView
        onClick={() => handleOpenNewTab("https://forms.gle/Di2PPFNx9j4xNy4YA")}
      >
        {" "}
        → Visit Us
      </LearningPageInfoLinkTextView>
    </>
  );
}

function DesktopPrimarySchoolGwanggyo() {
  return (
    <BodyView className="text_wrap">
      <PathStringView className="path">
        Learning {">"} gwanggyo Pre-K and Kinder Program (4~6 age)
      </PathStringView>
      <div className="separatorline" />
      <BannerView>
        <img
          src="https://playsongshome.playsongs.ai/charlie/learning/pc/0321/banner-gwang-01.png"
          alt="banner-elemantary"
          width={"100%"}
        />
      </BannerView>

      <LearningPageTitleView>
        gwanggyo Pre-K and Kinder Program (4~6 age)
      </LearningPageTitleView>
      <LearningPageInfoView>
        <LearningPageInfoDescriptionView>
          <LearningPageInfoTextView className="text_wrap">
            CHARLIE’S BIG RED HOUSE Gwanggyo campus features an integrated
            learning environment specially designed to stimulate the learning
            and development of children from primary Kinder Program, age 4
            through to 6 and elementary, age 7 to 9(grade 1,2,3), based on
            international curricular standards.
          </LearningPageInfoTextView>
          <LearningPageInfoText2View className="text_wrap">
            찰리스빅레드하우스 광교 캠퍼스는 국제적인 교과 과정 기준
            (International Curriculum Standard) 에 따라 5세부터 7세까지의 유치부
            프로그램으로 구성되어 있습니다. 아이들의 학습과 발달을 촉진하기 위해
            특별히 설계된 통합 학습 환경을 갖추고 있습니다.
          </LearningPageInfoText2View>

          <LearningPageInfoImgView>
            <img
              src="https://playsongshome.playsongs.ai/charlie/learning/gwang-pri-img01%403x.png"
              alt="img03"
              width={"100%"}
            />
          </LearningPageInfoImgView>

          <LearningPageInfoTextView2 className="text_wrap">
            Through an english immersion program where children’s interests and
            ideas inform the design of curricular experiences, using multiple
            ways of learning, incorporating social and emotional development,
            language, physical development, reading, writing, maths, social
            studies, musical, stem, science, and debate students develop to
            become a confident learner.
          </LearningPageInfoTextView2>
          <LearningPageInfoImgView>
            <img
              src="https://playsongshome.playsongs.ai/charlie/learning/gwang-pri-img02%403x.png"
              alt="img04"
              width={"100%"}
            />
          </LearningPageInfoImgView>
          <LearningPageInfoText2View className="text_wrap">
            아이들의 관심사와 생각을 중점으로 한 영어 몰입교육 프로그램으로 사회
            및 정서 발달, 언어, 신체 발달, 읽기, 쓰기, 수학, 사회, 음악, STEM,
            과학 및 토론을 통합하는 다양한 학습 방법을 통해 새로운 환경을
            탐색하며 배움과 학습에 자신감이 있는 학생으로 성장합니다.
          </LearningPageInfoText2View>
        </LearningPageInfoDescriptionView>

        <LearningPageInfoLinkSectionView>
          <LearningPageInfoLinkView>
            <LearningPageInfoLinkTextView
              onClick={() =>
                handleOpenNewTab(
                  "https://docs.google.com/forms/d/1L-ghFFkiyTEu-Kkp0HI-aofdtNXR7E_wi0mgdGX1KMQ/viewform?edit_requested=true"
                )
              }
            >
              {" "}
              → Request information
            </LearningPageInfoLinkTextView>
            <LearningPageInfoLinkText2View
              onClick={() =>
                handleOpenNewTab("https://forms.gle/Di2PPFNx9j4xNy4YA")
              }
            >
              {" "}
              → Visit Us
            </LearningPageInfoLinkText2View>
          </LearningPageInfoLinkView>
        </LearningPageInfoLinkSectionView>
      </LearningPageInfoView>
    </BodyView>
  );
}
export default PrimarySchoolGwanggyo;
